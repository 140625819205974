@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@800&display=swap");

.thumbnail {
  position: absolute;
  top: 0;
  z-index: -5000;
  width: 0px;
  height: 0px;
}
.thumbnail img {
  object-fit: cover;
  width: 0px;
  height: 0px;
}

.bn-onboard-custom {
  z-index: 2323;
}

.clickable {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.clickable:hover {
  color: #be1fda;
}

html,
body {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}
html {
  padding: 0;
  height: 100%;
  /* background-color: #17408b; */
  background: linear-gradient(90deg, #17408b 50%, #c9082a 50%);
  /* background: linear-gradient(90deg, #fff 50%, #c9082a 50%); */
  object-fit: contain;
}
body {
  height: 100%;
  background-color: transparent;
  /* max-width: 1280px; */
  margin: auto;
}
h1,
h2,
h4,
h5 {
  font-weight: 700;
  color: #ffffff;
}
h4,
h5 {
  font-weight: 500;
  color: #ffffff;
}
#root {
  height: 100%;
}
#application {
  padding-top: 0;
  background: none;
  position: relative;
  min-height: 100%;
  /* padding-bottom: 100px; */
}
.btn.round {
  border-radius: 0.6rem;
}
.btn-green {
  background-color: #94e316;
  color: black;
}

.btn-peach {
  border-color: #be1fda;
  border: solid 3px #be1fda;
  background-color: #be1fda;
  color: black;
  white-space: pre-wrap;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.btn-green.focus,
.btn-green:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(148, 227, 22, 0.25);
}

.btn-peach.focus,
.btn-peach:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(189, 92, 153, 0.25);
}

.btn-outline {
  background: none;

  border: solid 3px;
}

.btn-outline.btn-peach {
  border-color: #be1fda;
  color: white;
}

.btn-outline.btn-peach:hover {
  background-color: #be1fda;
  color: #fff;
}

.btn-peach:hover {
  background-color: #1b1e25;
  color: #be1fda;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background: rgba(0, 0, 0, 0.6);
}

.modal-content {
  background-color: #3f3d3a;
}

.modal-header {
  border-bottom: 1px solid #949393;
}
.modal-footer {
  border-top: 1px solid #949393;
}

.close {
  color: #fff;
  text-shadow: 0 1px 0 #333;
  opacity: 0.5;
}

.toastContainer {
  z-index: 2000;
}

.text-peach {
  color: #be1fda;
}

.text-lilac {
  color: #771197;
}

.bold-text {
  font-weight: 400;
}

/* modal overrides... */
.web3modal-provider-container {
  padding: 4px !important;
}
.web3modal-provider-icon {
  width: 32px !important;
  height: 32px !important;
}
.web3modal-provider-name {
  font-size: 1.1em !important;
  margin-top: 0.3em !important;
}
.web3modal-provider-description {
  font-size: 0.9em !important;
  margin: 0.2em 0px !important;
}
.web3modal-modal-card {
  max-width: 710px !important;
}

.bn-logo {
  margin-bottom: 5px;
}
.MuiAccordionSummary-content {
  margin: 24px 0px 24px 0px !important;
}
.MuiAccordionDetails-root{
  padding-top: 0px !important;
  padding-left: 24px !important;
}
.MuiCheckbox-root {
  padding:unset !important;
}
.css-tg0y58-MuiPaper-root-MuiAccordion-root{
  box-shadow: unset !important;
}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop{
  background-color: unset !important;
}
.css-xfade8-MuiInputBase-root-MuiInput-root::after{
border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Overpass:wght@800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "poppins";
  src: local("poppins"), url(/static/media/Poppins.8081832f.ttf) format("truetype");
}

@font-face {
  font-family: "poppins-semibold";
  src: local("poppins-semibold"),
    url(/static/media/Poppins-SemiBold.cce5625b.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: 'bau';
  src: local('bau'), url(/static/media/Bau-Regular.081b7d08.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: "bjorke";
  src: local("bjorke"), url(/static/media/The-Bjorke.22c6f986.ttf) format("truetype");
}
@font-face {
  font-family: "amallia";
  src: local("amallia"),
    url("/static/media/Amallia and Ronaldo.1e1628f6.otf") format("truetype");
}

@font-face {
  font-family: "abc-grav";
  src: local("abc-grav"), url(/static/media/abc-grav.62e74fd3.tff) format("truetype");
}
@font-face {
  font-family: "digital";
  src: local("digital"), url(/static/media/digital.93911ab1.ttf) format("truetype");
}
@font-face {
  font-family: "roboto-mono";
  src: local("roboto-mono"),
    url(/static/media/RobotoMono-Regular.418a8f9f.ttf) format("truetype");
}
@font-face {
  font-family: "action-nba";
  src: local("action-nba"),
    url(/static/media/action-nba.e16d198d.ttf) format("truetype");
}
body > li > .grabbed-layer {
  box-shadow: 0 0 30px #ffffff33;
}

.thumbnail {
  position: absolute;
  top: 0;
  z-index: -5000;
  width: 0px;
  height: 0px;
}
.thumbnail img {
  object-fit: cover;
  width: 0px;
  height: 0px;
}

.bn-onboard-custom {
  z-index: 2323;
}

.clickable {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.clickable:hover {
  color: #be1fda;
}

html,
body {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}
html {
  padding: 0;
  height: 100%;
  /* background-color: #17408b; */
  background: linear-gradient(90deg, #17408b 50%, #c9082a 50%);
  /* background: linear-gradient(90deg, #fff 50%, #c9082a 50%); */
  object-fit: contain;
}
body {
  height: 100%;
  background-color: transparent;
  /* max-width: 1280px; */
  margin: auto;
}
h1,
h2,
h4,
h5 {
  font-weight: 700;
  color: #ffffff;
}
h4,
h5 {
  font-weight: 500;
  color: #ffffff;
}
#root {
  height: 100%;
}
#application {
  padding-top: 0;
  background: none;
  position: relative;
  min-height: 100%;
  /* padding-bottom: 100px; */
}
.btn.round {
  border-radius: 0.6rem;
}
.btn-green {
  background-color: #94e316;
  color: black;
}

.btn-peach {
  border-color: #be1fda;
  border: solid 3px #be1fda;
  background-color: #be1fda;
  color: black;
  white-space: pre-wrap;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.btn-green.focus,
.btn-green:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(148, 227, 22, 0.25);
}

.btn-peach.focus,
.btn-peach:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(189, 92, 153, 0.25);
}

.btn-outline {
  background: none;

  border: solid 3px;
}

.btn-outline.btn-peach {
  border-color: #be1fda;
  color: white;
}

.btn-outline.btn-peach:hover {
  background-color: #be1fda;
  color: #fff;
}

.btn-peach:hover {
  background-color: #1b1e25;
  color: #be1fda;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background: rgba(0, 0, 0, 0.6);
}

.modal-content {
  background-color: #3f3d3a;
}

.modal-header {
  border-bottom: 1px solid #949393;
}
.modal-footer {
  border-top: 1px solid #949393;
}

.close {
  color: #fff;
  text-shadow: 0 1px 0 #333;
  opacity: 0.5;
}

.toastContainer {
  z-index: 2000;
}

.text-peach {
  color: #be1fda;
}

.text-lilac {
  color: #771197;
}

.bold-text {
  font-weight: 400;
}

/* modal overrides... */
.web3modal-provider-container {
  padding: 4px !important;
}
.web3modal-provider-icon {
  width: 32px !important;
  height: 32px !important;
}
.web3modal-provider-name {
  font-size: 1.1em !important;
  margin-top: 0.3em !important;
}
.web3modal-provider-description {
  font-size: 0.9em !important;
  margin: 0.2em 0px !important;
}
.web3modal-modal-card {
  max-width: 710px !important;
}

.bn-logo {
  margin-bottom: 5px;
}
.MuiAccordionSummary-content {
  margin: 24px 0px 24px 0px !important;
}
.MuiAccordionDetails-root{
  padding-top: 0px !important;
  padding-left: 24px !important;
}
.MuiCheckbox-root {
  padding:unset !important;
}
.css-tg0y58-MuiPaper-root-MuiAccordion-root{
  box-shadow: unset !important;
}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop{
  background-color: unset !important;
}
.css-xfade8-MuiInputBase-root-MuiInput-root::after{
border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

:root {
  --primary-color-counter: #ff692b;
}

#txRotator {
  -webkit-animation-name: playRotating;
          animation-name: playRotating;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
@-webkit-keyframes playRotating {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes playRotating {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.counter-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 15px 15px;
  gap: 15px 15px;
  margin-bottom: 30px;
  margin-top: 30px;
}
@media (max-width: 454px) {
  .counter-container {
    padding: 0 80px;
  }
}
@media (max-width: 400px) {
  .counter-container {
    padding: 0 30px;
  }
}
@media (max-width: 302px) {
  .counter-container {
    padding: 0;
  }
}
.counter-rotary-holder {
  width: 95%;
  height: 95%;
  position: absolute;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  z-index: 5;
  transition: all 0.3s ease-in-out;
}

.counter-content-holder {
  background-color: white;
  width: 75px;
  height: 75px;
  border-radius: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.counter-rotary-item {
  width: 15px;
  height: 15px;
  border: 2px solid #ff692b;
  border: 2px solid var(--primary-color-counter);
  border-radius: 50%;
  position: absolute;
  background-color: white;
}
.counter-circle {
  width: 88px;
  height: 88px;
  border: 2px solid #ff692b;
  border: 2px solid var(--primary-color-counter);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: #ecebe8;
  position: relative;
}

.counter-text {
  font-family: "poppins";
  font-size: 12px;
  line-height: 18px;
  color: #030300;
  z-index: 1;
}

.counter-text-value {
  font-family: "poppins";
  font-size: 27px;
  color: #020000;
  letter-spacing: -2px;
  line-height: 32px;
}

.disabled .counter-rotary-item,
.disabled .counter-circle {
  border-color: black;
}


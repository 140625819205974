body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "poppins";
  src: local("poppins"), url(./assets/fonts/Poppins.ttf) format("truetype");
}

@font-face {
  font-family: "poppins-semibold";
  src: local("poppins-semibold"),
    url(./assets/fonts/Poppins-SemiBold.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: 'bau';
  src: local('bau'), url(./assets/fonts/Bau-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: "bjorke";
  src: local("bjorke"), url(./assets/fonts/The-Bjorke.ttf) format("truetype");
}
@font-face {
  font-family: "amallia";
  src: local("amallia"),
    url(./assets/fonts/Amallia\ and\ Ronaldo.otf) format("truetype");
}

@font-face {
  font-family: "abc-grav";
  src: local("abc-grav"), url(./assets/fonts/abc-grav.tff) format("truetype");
}
@font-face {
  font-family: "digital";
  src: local("digital"), url(./assets/fonts/digital.ttf) format("truetype");
}
@font-face {
  font-family: "roboto-mono";
  src: local("roboto-mono"),
    url(./assets/fonts/RobotoMono-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "action-nba";
  src: local("action-nba"),
    url(./assets/fonts/action-nba.ttf) format("truetype");
}
body > li > .grabbed-layer {
  box-shadow: 0 0 30px #ffffff33;
}
